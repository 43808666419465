import States from "./states";

class Google {
  constructor() {
    bindAll(this, ["_bindTracker"]);
  }

  bind() {
    this.trackers = document.body.querySelectorAll(
      "[data-ga-category][data-ga-action]",
    );

    for (let i = 0, j = this.trackers.length; i < j; i++) {
      this.trackers[i].addEventListener("click", this._bindTracker);
    }
  }

  unbind() {
    for (let i = 0, j = this.trackers.length; i < j; i++) {
      this.trackers[i].removeEventListener("click", this._bindTracker);
    }
  }

  _bindTracker(event) {
    const category = event.currentTarget.getAttribute("data-ga-category");
    const action = event.currentTarget.getAttribute("data-ga-action");
    this.pushCustomTracker(category, action);
  }

  pushCustomTracker(category, action) {
    if (window.gtag) {
      gtag("event", action, {
        event_category: category,
      });
      console.log("Push Tracker", category, action);
    }
  }
}

export default new Google();

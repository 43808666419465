import Component from "../component";
import Dropdown from "../../helpers/dropdown";

export default class DropdownLang extends Component {
  constructor(...args) {
    super(...args);
  }

  init() {
    const dropdown = new Dropdown({ domComponent: this.dom.component });
  }
}

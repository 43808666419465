/**
 * Return the center of an element
 *
 * @param  {number} element
 * @return {object} Center
 */
function getElementCenter(element) {
  const { top, right, bottom, left } = element.getBoundingClientRect();

  return {
    x: (left + right) / 2,
    y: (top + bottom) / 2,
  };
}

window.getElementCenter = function (element) {
  return getElementCenter(element);
};

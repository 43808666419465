import Component from "../../component";

export default class BannerCountdown extends Component {
  constructor(...args) {
    super(...args);
    this._retrieveDom();

    this.timeDifference = Math.abs(
      new Date() - this.dom.wrapper.dataset.timestamp,
    );
    this.updateCountdown();
  }

  _retrieveDom() {
    this.dom.wrapper = document.getElementById("wrapper");
    this.dom.days = document.getElementById("days");
    this.dom.hours = document.getElementById("hours");
    this.dom.minutes = document.getElementById("minutes");
    this.dom.seconds = document.getElementById("seconds");
  }

  updateCountdown() {
    const days = Math.floor(this.timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (this.timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor(
      (this.timeDifference % (1000 * 60 * 60)) / (1000 * 60),
    );
    const seconds = Math.floor((this.timeDifference % (1000 * 60)) / 1000);

    this.dom.days.innerHTML = days < 10 ? `0${days}` : days;
    this.dom.hours.innerHTML = hours < 10 ? `0${hours}` : hours;
    this.dom.minutes.innerHTML = minutes < 10 ? `0${minutes}` : minutes;
    this.dom.seconds.innerHTML = seconds < 10 ? `0${seconds}` : seconds;

    this.timeDifference = this.timeDifference - 1000;

    setTimeout(this.updateCountdown.bind(this), 1000);
  }
}

import EventEmitter from "eventemitter3";

/**
 * Dropdown dom classes
 * @type {{CONTAINER: string, HANDLER: string, SHUTTER: string, INNER: string}}
 */
export const dropdownSelectors = {
  CONTAINER: "dropdown-container",
  HANDLER: "dropdown-handler",
  SHUTTER: "dropdown",
  INNER: "dropdown-inner",
};

/**
 * Dropdown open/close panel on handler click/hover
 *
 * Example :
 * <div class="dropdown-container" data-handler-type="hover">
 *     <div class="dropdown-handler"></div>
 *     <div class="dropdown">
 *         <div class="dropdown-inner">
 *             ...content here
 *         </div>
 *     </div>
 * </div>
 *
 */
export default class Dropdown extends EventEmitter {
  /**
   *  Create a dropdown
   * @param {boolean} initOpen - Is dropdown open on init
   * @param {string} handlerType - How the dropdown open (values: 'click', 'hover')
   * @param {string} containerSelector - Selector for container dom element
   * @param {string} handlerSelector - Selector for handler dom element
   * @param {string} dropdownSelector - Selector for dropdown dom element
   * @param {string} innerSelector - Selector for inner dom element
   * @constructor
   */
  constructor({
    domComponent = domComponent,
    initOpen = false,
    handlerType = "click",
    containerSelector = "." + dropdownSelectors.CONTAINER,
    handlerSelector = "." + dropdownSelectors.HANDLER,
    dropdownSelector = "." + dropdownSelectors.SHUTTER,
    innerSelector = "." + dropdownSelectors.INNER,
  } = {}) {
    super();

    // Dom elements
    this.initOpen = initOpen;
    this.handlerType = handlerType;
    this.containerSelector = containerSelector;
    this.handlerSelector = handlerSelector;
    this.dropdownSelector = dropdownSelector;
    this.innerSelector = innerSelector;
    this.dropdownLock = false;
    this.domRef = domComponent;

    this.init();
  }

  /**
   * Dropdown height
   * @return {number}
   */
  get dropdownHeight() {
    return this.dom.dropdownInner.offsetHeight;
  }

  /**
   * init dropdown
   */
  init() {
    this._retrieveDOM();
    this._getHandlerType();
    this._setDefaultClasses();

    // open on init
    if (this.initOpen) this.open();

    // hover handlers
    if (this.handlerType === "hover") {
      this.dom.container.addEventListener("mouseenter", this.open.bind(this));
      this.dom.container.addEventListener("mouseleave", this.close.bind(this));
      this.dom.container.addEventListener("click", () => {
        this.open();
        this.dropdownLock = !this.dropdownLock;
      });

      // click handlers
    } else {
      this.dom.dropdownHandler.addEventListener(
        "click",
        this._handleState.bind(this),
      );
    }
  }

  /**
   * Open the pan
   * @fires Dropdown#open
   */
  open() {
    this.dom.container.classList.add("open");
    this.dom.dropdown.style.maxHeight = this.dropdownHeight + "px";
    this.emit("open");
  }

  /**
   * Close the pan
   * @fires Dropdown#close
   */
  close() {
    if (this.dropdownLock) return;
    this.dom.dropdown.style.maxHeight = 0;
    this.dom.container.classList.remove("open");
    this.emit("close");
  }

  /**
   * get handler type from dom element data attribute
   * @private
   */
  _getHandlerType() {
    if (
      this.dom.container.dataset.handlerType &&
      this.dom.container.dataset.handlerType.length > 0
    )
      this.handlerType = this.dom.container.dataset.handlerType;
  }

  /**
   * get dom elements from selectors
   * @private
   */
  _retrieveDOM() {
    this.dom = {};
    this.dom.wrapper = this.domRef;
    this.dom.container = this.dom.wrapper;
    this.dom.dropdown = this.dom.container.querySelector(this.dropdownSelector);
    this.dom.dropdownInner = this.dom.container.querySelector(
      this.innerSelector,
    );
    this.dom.dropdownHandler = this.dom.container.classList.contains(
      dropdownSelectors.HANDLER,
    )
      ? this.dom.container
      : this.dom.container.querySelector(this.handlerSelector);
  }

  /**
   * Set default dom classes
   * @private
   */
  _setDefaultClasses() {
    this.dom.container.classList.add(dropdownSelectors.CONTAINER);
    this.dom.dropdown.classList.add(dropdownSelectors.SHUTTER);
    this.dom.dropdownInner.classList.add(dropdownSelectors.INNER);
    this.dom.dropdownHandler.classList.add(dropdownSelectors.HANDLER);
  }

  /**
   * Handle open/close state
   * @param {Event} e - Event object
   * @private
   */
  _handleState(e) {
    //var handler = e.target;
    e.preventDefault();
    if (!this.dom.container.classList.contains("open")) {
      this.open();
    } else {
      this.close();
    }
  }
}

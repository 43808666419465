import Component from "../component";

export default class Gallery extends Component {
  constructor(...args) {
    super(...args);
  }
  init() {
    const pagination = this.dom.component.querySelector(".pagination");
    pagination.style.display = "none";
  }
}

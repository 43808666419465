import Component from "../component";
import Swiper, { Navigation, Mousewheel } from "swiper";

export default class Timeline extends Component {
  constructor(...args) {
    super(...args);

    this._retrieveDOM();
  }

  _retrieveDOM() {
    this.dom.slider = this.dom.component.querySelector(".slider");
  }

  init() {
    this.Slider = new Swiper(this.dom.slider, {
      modules: [Navigation, Mousewheel],
      direction: "horizontal",
      slidesPerView: "auto",
      navigation: {
        nextEl: this.dom.component.querySelector(".navigation-next"),
        prevEl: this.dom.component.querySelector(".navigation-prev"),
      },
      on: {
        init: (swiperInstance) => {
          const isBeginning = swiperInstance.realIndex === 0;
          const isEnd =
            swiperInstance.realIndex === swiperInstance.slides.length - 1;

          if (isBeginning && isEnd) {
            swiperInstance.el.classList.add("swiper-no-navigation");
          } else {
            swiperInstance.el.classList.remove("swiper-no-navigation");
          }
        },
      },
    });
  }
}

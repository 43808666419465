/**
 * Return the hexadecimal value
 *
 * @param  {number} r      Red
 * @param  {number} g      Green
 * @param  {number} b      Blue
 * @param  {string} prefix Prefix
 * @return {string}        Hexadecimal
 */
function rgbToHex(r, g, b, prefix = "") {
  return prefix + ((r << 16) + (g << 8) + b).toString(16).padStart(6, "0");
}

window.rgbToHex = function (r, g, b) {
  return rgbToHex(r, g, b);
};

/**
 * Check if value is an Array
 *
 * @param  {any} value Value
 * @return {boolean}   True if value is an Array, false otherwise
 */
function isArray(value) {
  return !!(value && value.constructor === Array);
}

window.isArray = function (value) {
  return isArray(value);
};

import Component from "../component";
import SimpleSharePopin from "../../helpers/simple-share-popin";

export default class SocialShare extends Component {
  _classes = {
    button: "js-social-network-share-button",
  };

  constructor(...args) {
    super(...args);
    this._retrieveDOM();
  }

  _retrieveDOM() {
    this.dom.views = {};
    this.dom.views.buttons = this.dom.component.querySelectorAll(
      "." + this._classes.button,
    );
  }

  init() {
    super.init();
    new SimpleSharePopin(this.dom.views.buttons);
  }
}

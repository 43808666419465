import Page from "../page";
import States from "../../helpers/states";

export default class MasterIndex extends Page {
  constructor(...args) {
    super(...args);

    this._retrieveDOM();
    this._initTls();
  }

  // init() {
  //     super.init();
  // }

  /**
   * init() {}
   * bind() {}
   * unbind() {}
   * resize(width, height) {}
   * _destroy() {}
   */
}

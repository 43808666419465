import Component from "../component";
import Swiper, { Mousewheel, Pagination, Autoplay } from "swiper";

export default class PartnersList extends Component {
  constructor(...args) {
    super(...args);

    this._retrieveDOM();
  }

  _retrieveDOM() {
    this.dom.slide = this.dom.component.querySelectorAll(".swiper-slide");
    this.dom.slider = this.dom.component.querySelector(".list-mobile");
  }

  init() {
    this.Slider = new Swiper(this.dom.slider, {
      modules: [Mousewheel, Pagination, Autoplay],
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 16,
      pagination: {
        el: this.dom.component.querySelector(".pagination"),
        type: "bullets",
        clickable: true,
      },
      loop: true,
      autoplay: {
        delay: 3500,
      },
    });
  }
  /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}

import Component from "../component";
import Dropdown from "../../helpers/dropdown";

export default class CardExpand extends Component {
  constructor(...args) {
    super(...args);
  }

  init() {
    const dropdown = new Dropdown({ domComponent: this.dom.component });
  }

  /*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}

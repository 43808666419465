/**
 * Delegates event to a selector
 *
 * @param  {Element}  element
 * @param  {string}   selector
 * @param  {string}   type
 * @param  {Function} callback
 * @param  {boolean}  useCapture
 * @return {Object}
 */
function delegate(element, selector, type, callback, useCapture) {
  let listenerFn = listener.apply(this, arguments);
  element.addEventListener(type, listenerFn, useCapture);

  return {
    destroy: () => {
      element.removeEventListener(type, listenerFn, useCapture);
    },
  };
}

/**
 * Finds closest match and invokes callback
 *
 * @param  {Element}  element
 * @param  {string}   selector
 * @param  {string}   type
 * @param  {Function} callback
 * @return {Function}
 */
function listener(element, selector, type, callback) {
  return (event) => {
    event.delegateTarget = closest(event.target, selector);

    if (event.delegateTarget) {
      callback.call(element, event);
    }
  };
}

window.delegate = function (element, selector, type, callback, useCapture) {
  return delegate(element, selector, type, callback, useCapture);
};

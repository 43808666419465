/**
 * Normalize a value between two bounds
 *
 * @param  {number} value Value to normalize
 * @param  {number} min   Minimum boundary
 * @param  {number} max   Maximum boundary
 * @return {number}       Normalized value
 */
function normalize(value, min, max) {
  return (value - min) / (max - min);
}

window.normalize = function (value, min, max) {
  return normalize(value, min, max);
};

class States {
  constructor() {
    this.width = document.documentElement.clientWidth || window.innerWidth;
    this.height = document.documentElement.clientHeight || window.innerHeight;

    this.devicePixelRatio = window.devicePixelRatio;
    this.audioContext = window.AudioContext || window.webkitAudioContext;
    this.passive = this._detectPassiveMode();

    this.dom = {};
  }

  _detectPassiveMode() {
    let passive = false;
    try {
      const options = Object.defineProperty({}, "passive", {
        get: () => {
          passive = true;
        },
      });
      window.addEventListener("test", null, options);
    } catch (e) {}
    return passive;
  }
}

export default new States();

import Component from "../component";
import { gsap } from "../../gsap";
import { ScrollTrigger } from "../../gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class TrainingNavigation extends Component {
  constructor(...args) {
    super(...args);
    this._retrieveDom();
  }

  _retrieveDom() {
    this.dom.toggleButton = this.dom.component.querySelector(".mobile-menu");
  }

  _toggleMenu = (e) => {
    this.dom.component.classList.toggle("close");
  };

  activeScrolled = () => {
    this.dom.component.classList.add("scrolled");
  };

  disableScrolled = () => {
    this.dom.component.classList.remove("scrolled");
  };

  bind() {
    gsap.fromTo(
      this.dom.component,
      {},
      {
        scrollTrigger: {
          start: 0,
          end: "+=60%",
          onEnter: () => this.disableScrolled(),
          onEnterBack: () => this.disableScrolled(),
          onLeave: () => this.activeScrolled(),
          onLeaveBack: () => {
            if (window.scrollY >= 100) this.activeSrolled();
          },
        },
      },
    );

    this.dom.toggleButton.addEventListener("click", this._toggleMenu);
  }

  unbind() {
    this.dom.toggleButton.removeEventListener("click", this._toggleMenu);
  }
}

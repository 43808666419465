import Component from "../component";

export default class ButtonClassic extends Component {
  constructor(...args) {
    super(...args);
    this._retrieveDom();
  }

  _retrieveDom() {
    this.button = this.dom.component.querySelector(".button");
  }

  handleMouseEnter(e) {
    const offsetXPolyfill = e.offsetX || e.pageX - e.target.offset().left;
    const offsetYPolyfill = e.offsetY || e.pageY - e.target.offset().top;
    const XPercent = offsetXPolyfill / e.target.clientWidth;
    const YPercent = offsetYPolyfill / e.target.clientHeight;
    e.target.style.setProperty("--animation-origin-x", `${XPercent * 100}%`);
    e.target.style.setProperty("--animation-origin-y", `${YPercent * 100}%`);
  }

  handleMouseLeave(e) {
    const offsetXPolyfill = e.offsetX || e.pageX - e.target.offset().left;
    const offsetYPolyfill = e.offsetY || e.pageY - e.target.offset().top;
    const XPercent = offsetXPolyfill / e.target.clientWidth;
    const YPercent = offsetYPolyfill / e.target.clientHeight;
    e.target.style.setProperty("--animation-origin-x", `${XPercent * 100}%`);
    e.target.style.setProperty("--animation-origin-y", `${YPercent * 100}%`);
  }

  bind() {
    this.dom.component.addEventListener(
      "mouseenter",
      this.handleMouseEnter.bind(this),
    );
    this.dom.component.addEventListener(
      "mouseleave",
      this.handleMouseLeave.bind(this),
    );
  }

  unbind() {
    this.dom.component.removeEventListener(
      "mouseenter",
      this.handleMouseEnter.bind(this),
    );
    this.dom.component.removeEventListener(
      "mouseleave",
      this.handleMouseLeave.bind(this),
    );
  }
}

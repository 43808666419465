/**
 * Return the position of an element
 *
 * @param  {string} direction
 * @return {number} position
 */
function getWindowPosition(direction = "y") {
  return direction == "y" || direction == "vertical"
    ? window.pageYOffset ||
        document.documentElement.scrollTop -
          (document.documentElement.clientTop || 0)
    : window.pageXOffset ||
        document.documentElement.scrollLeft -
          (document.documentElement.clientLeft || 0);
}

window.getWindowPosition = function (direction) {
  return getWindowPosition(direction);
};

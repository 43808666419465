import Component from "../component";

export default class Events extends Component {
  constructor(...args) {
    super(...args);

    this._retrieveDOM();
  }

  _retrieveDOM() {
    this.dom.overlay = this.dom.component.querySelector(".overlay");
  }

  _onClick() {
    this.dom.overlay.classList.add("hidden");
  }

  bind() {
    this.dom.overlay.addEventListener("click", this._onClick.bind(this));
  }

  unbind() {
    this.dom.overlay.removeEventListener("click", this._onClick.bind(this));
  }
}

/**
 * Check if a number is odd
 *
 * @param  {number}  number
 * @return {boolean} True if the given number is odd, false otherwise
 */
function isOdd(number) {
  return !!(number & 1);
}

window.isOdd = function (number) {
  return isOdd(number);
};

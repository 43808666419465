/**
 * Return the rgb values
 *
 * @param  {string} hex Hexadecimal
 * @return {object}     RGB
 */
function hexToRgb(hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

window.hexToRgb = function (hex) {
  return hexToRgb(hex);
};

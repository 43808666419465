/**
 * Bind methods on a given context
 *
 * @param {object} ctx         Context
 * @param {array}  methodNames Method names
 */
function bindAll(ctx, methodNames) {
  for (let i = 0, l = methodNames.length; i < l; i++) {
    ctx[methodNames[i]] = ctx[methodNames[i]].bind(ctx);
  }
}

window.bindAll = function (ctx, methodNames) {
  return bindAll(ctx, methodNames);
};

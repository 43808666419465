import debounce from "lodash.debounce";
import Emitter from "./Emitter";

const config = {
  resizeDebounceDelay: 50,
};

// Manage all the window resizing in one place
// Store width/height to avoid repaint
class Stage {
  // ------ static
  static RESIZED = "stage_resized";
  static BLURRED = "stage_blured";
  static FOCUSED = "stage_focused";

  constructor() {
    this._binded = false;

    this.width = window.innerWidth;
    this.height = window.innerHeight;

    this.webGLEl = document.body.querySelector(".webgl");

    if (this.webGLEl) {
      let bbox = this.webGLEl.getBoundingClientRect();
      this.wWidth = bbox.width;
      this.wHeight = bbox.height;
    }

    this.pixelRatio = window.devicePixelRatio;

    this.prWidth = this.width * this.pixelRatio;
    this.prHeight = this.height * this.pixelRatio;

    this.aspect = this.width / this.height;

    this.isFocused = true;

    this._handleResize = this._handleResize.bind(this);
    this._handleBlur = this._handleBlur.bind(this);
    this._handleFocus = this._handleFocus.bind(this);
    this.bind();
  }

  // ====== public
  bind() {
    if (this._binded) return;

    this._handleDebounceResize = debounce(
      this._handleResize,
      config.resizeDebounceDelay,
    );

    window.addEventListener("resize", this._handleDebounceResize, false);
    window.addEventListener("blur", this._handleBlur, false);
    window.addEventListener("focus", this._handleFocus, false);

    this._binded = true;
  }

  unbind() {
    if (!this._binded) return;

    window.removeEventListener("resize", this._handleDebounceResize, false);
    window.removeEventListener("blur", this._handleBlur, false);
    window.removeEventListener("focus", this._handleFocus, false);

    this._binded = false;
  }

  addResize(cb) {
    Emitter.on(Stage.RESIZED, cb);
  }

  removeResize(cb) {
    Emitter.off(Stage.RESIZED, cb);
  }

  // ====== private
  // --- handlers

  _handleResize(event) {
    this.pixelRatio = window.devicePixelRatio;

    this.width = window.innerWidth;
    this.height = window.innerHeight;

    if (this.webGLEl) {
      let bbox = this.webGLEl.getBoundingClientRect();
      this.wWidth = bbox.width;
      this.wHeight = bbox.height;
    } else {
      this.wWidth = this.width;
      this.wHeight = this.height;
    }

    this.prWidth = this.width * this.pixelRatio;
    this.prHeight = this.height * this.pixelRatio;

    this.aspect = this.width / this.height;
    Emitter.emit(Stage.RESIZED, this.width, this.height, this.aspect);
  }

  _handleBlur(event) {
    this.isFocused = false;
    Emitter.emit(Stage.BLURRED, event);
  }

  _handleFocus(event) {
    this.isFocused = true;
    Emitter.emit(Stage.FOCUSED, event);
  }
}

export default new Stage();

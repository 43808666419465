/**
 * Diagonal of a rectangle
 *
 * @param  {number} width  Width of the rectangle
 * @param  {number} height Height of the rectangle
 * @return {number}        Diagonal length
 */
function diagonal(width, height) {
  return Math.sqrt(width * width + height * height);
}

window.diagonal = function (width, height) {
  return diagonal(width, height);
};

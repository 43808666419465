/**
 * Open a share popin for Facebook, Twitter et Linkedin
 */
export default class SimpleSharePopin {
  constructor(els) {
    this.dom = {
      link: els,
    };

    this._prepare();
  }

  _prepare() {
    for (let i = 0; i < this.dom.link.length; i++) {
      let el = this.dom.link[i];
      let where = this.dom.link[i].getAttribute("data-social");
      this._switch(el, where);
    }
  }

  _switch(el, where) {
    switch (where) {
      case "facebook":
        this._shareFb(el);
        break;

      case "twitter":
        this._shareTw(el);
        break;

      case "linkedin":
        this._shareLink(el);
        break;
    }
  }

  _shareFb(el) {
    let st =
      "popUp=window.open('http://www.facebook.com/sharer.php?u=" +
      document.URL +
      "','popupwindow','scrollbars=yes,width=800,height=400');popUp.focus();return false;";
    el.setAttribute("onclick", st);
  }

  _shareTw(el) {
    let msg = el.getAttribute("data-message") || "";
    let st =
      "popUp=window.open('http://twitter.com/intent/tweet?text=" +
      msg +
      " " +
      encodeURIComponent(document.URL) +
      "','popupwindow','scrollbars=yes,width=800,height=400');popUp.focus();return false";
    el.setAttribute("onclick", st);
  }

  _shareLink(el) {
    let st =
      "popUp=window.open('http://www.linkedin.com/shareArticle?url=" +
      document.URL +
      "','popupwindow','scrollbars=yes,width=800,height=400');popUp.focus();return false";
    el.setAttribute("onclick", st);
  }
}

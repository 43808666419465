import Component from "../component";

export default class Trainings extends Component {
  constructor(...args) {
    super(...args);

    this._retrieveDOM();
  }

  _retrieveDOM() {
    this.dom.opener = this.dom.component.querySelector(".video-opener");
    this.dom.closer = this.dom.component.querySelector(".video-closer");
    this.dom.video = this.dom.component.querySelector(".video-container");
    this.dom.videoCover = this.dom.component.querySelector(".video-cover");
  }

  _openVideo() {
    this.dom.video.classList.remove("hidden");
    document.body.style.overflow = "hidden";
    this.dom.videoCover.classList.add("hidden");
  }

  _closeVideo() {
    this.dom.video.classList.add("hidden");
    document.body.style.overflow = "auto";
    this.dom.videoCover.classList.remove("hidden");
  }

  init() {
    if (!this.dom.opener || !this.dom.closer || !this.dom.video) return;
    this.dom.opener.addEventListener("click", this._openVideo.bind(this));
    this.dom.closer.addEventListener("click", this._closeVideo.bind(this));
  }

  unbind() {
    this.dom.opener.removeEventListener("click", this._openVideo.bind(this));
    this.dom.closer.removeEventListener("click", this._closeVideo.bind(this));
  }
}

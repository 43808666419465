import * as components from "../components/index";
import Home from "../pages/homepage/homepage-index";
import Training from "../pages/training/training-index";
import Master from "../pages/master/master-index";
import Form from "../pages/form/form-index";
import Lexicon from "../pages/lexicon/lexicon-index";
import News from "../pages/news/news-index";
import NotFound from "../pages/404/404-index";

export const map = {
  components: {
    customCursor: components.customCursor,
    heroHome: components.heroHome,
    heroTraining: components.heroTraining,
    bannerCountdown: components.bannerCountdown,
    slider: components.slider,
    header: components.header,
    buttonClassic: components.buttonClassic,
    partnersList: components.partnersList,
    video: components.video,
    trainings: components.trainings,
    events: components.events,
    listing: components.listing,
    trainingNavigation: components.trainingNavigation,
    cardExpand: components.cardExpand,
    customCursor: components.customCursor,
    gallery: components.gallery,
    socialShare: components.socialShare,
    timeline: components.timeline,
    dropdown: components.dropdown,
  },
  pages: {
    homepage: {
      index: Home,
    },
    training: {
      index: Training,
    },
    master: {
      index: Master,
    },
    form: {
      index: Form,
    },
    lexicon: {
      index: Lexicon,
    },
    news: {
      index: News,
    },
    404: {
      index: NotFound,
    },
  },
};

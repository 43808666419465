/**
 * Linear interpolation between two values (lerping)
 *
 * @param  {number} x     First point
 * @param  {number} y     Second point
 * @param  {number} value Value to interpolate
 * @return {number}       Lerped value
 */
function lerp(x, y, value) {
  return x + (y - x) * value;
}

window.lerp = function (x, y, value) {
  return lerp(x, y, value);
};
